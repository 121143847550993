import React, { useState, useEffect } from 'react';
import {
  AppBar,
  Toolbar,
  Typography,
  Button,
  IconButton,
  Box,
  Drawer,
  List,
  ListItem,
  ListItemText,
  useTheme,
  useMediaQuery,
} from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import MenuIcon from '@mui/icons-material/Menu';
import { GiSun, GiMoon } from 'react-icons/gi';
import { motion } from 'framer-motion'; 


const Navbar = ({ toggleTheme }) => {
  const [mobileOpen, setMobileOpen] = useState(false);
  const [isDarkMode, setIsDarkMode] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const menuItems = [
    { text: 'Services', path: '/services' },
    { text: 'Projets', path: '/projects' },
    { text: 'Contact', path: '/contact' },
  ];

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const drawer = (
    <Box sx={{ width: 250 }} role="presentation" onClick={handleDrawerToggle}>
      <List>
        {menuItems.map((item) => (
          <ListItem 
            key={item.text} 
            component={RouterLink} 
            to={item.path}
            sx={{
              color: 'text.primary',
              backgroundColor: 'background.paper',
              boxShadow: 'none',
              borderRadius: 'none',
            }}
          >
            <ListItemText 
              primary={item.text}
              primaryTypographyProps={{
                sx: {
                  fontWeight: 500,
                  fontSize: '1.1rem',
                }
              }}
            />
          </ListItem>
        ))}
      </List>
    </Box>
  );

  useEffect(() => {
    const styles = `
      @keyframes lightsweep {
        0% { left: -100%; color: #FFB6C1; }
        50% { left: 100%; color: #FFB6C1; }
        100% { left: -100%; color: #FFB6C1; }
      }
      @keyframes gradient-animation {
        0% {
          background-position: 100% 50%;
        }
        100% {
          background-position: 0% 50%;
        }
      }
    `;
    const styleSheet = document.createElement("style");
    styleSheet.type = "text/css";
    styleSheet.innerText = styles;
    document.head.appendChild(styleSheet);
    return () => {
      document.head.removeChild(styleSheet);
    };
  }, []);

  const handleThemeToggle = () => {
    setIsDarkMode(!isDarkMode);
    toggleTheme();
  };

  return (
    <AppBar 
      position="sticky" 
      sx={{ 
        backgroundColor: 'rgba(255, 255, 255, 0.4)', 
        backdropFilter: 'blur(10px)', 
        boxShadow: 'none',
        borderRadius: 'none',
      }}
    >
      <Toolbar sx={{ justifyContent: 'space-between', py: { xs: 1, md: 2 }, alignItems: 'baseline' }}>
        <div className='navbar' style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'baseline', margin: '0 auto', width: '100%', padding: '0 20px' }}>
          <div style={{ display: 'flex', alignItems: 'flex-end' }}>
            <img src="/images/astraluxLogo.png" alt="Astralux Logo" style={{ width: '50px', height: 'auto', marginRight: '10px', marginTop: '5px' }} />
            <Typography
              variant="h6"
              component={RouterLink}
              to="/"
              sx={{
                textDecoration: 'none',
                fontWeight: 'normal',
                fontSize: 'inherit',
                color: 'black',
              }}
            >
              Astra Lux
            </Typography>
          </div>
          <div>
            {isMobile ? (
              <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="start"
                onClick={handleDrawerToggle}
                sx={{ color: 'text.primary', borderRadius: 'none' }}
              >
                <MenuIcon />
              </IconButton>
            ) : (
              <Box sx={{ display: 'flex', gap: 2 }}>
                {menuItems.filter(item => item.text !== 'Accueil').map((item) => (
                  <Button
                    key={item.text}
                    component={RouterLink}
                    to={item.path}
                    sx={{
                      color: 'text.primary',
                      backgroundColor: 'transparent',
                      boxShadow: 'none',
                      borderRadius: 'none',
                    }}
                  >
                    {item.text}
                  </Button>
                ))}
              </Box>
            )}
          </div>
        </div>

        <Drawer
          variant="temporary"
          anchor="right"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, 
          }}
          sx={{
            display: { xs: 'block', md: 'none' },
            '& .MuiDrawer-paper': { 
              boxSizing: 'border-box', 
              width: 250,
              backgroundColor: 'background.paper',
              borderRadius: 'none',
            },
          }}
        >
          {drawer}
        </Drawer>
      </Toolbar>
    </AppBar>
  );
};

export default Navbar;
