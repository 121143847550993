import React from 'react';
import {
  Container,
  Typography,
  Box,
  Card,
  CardContent,
  useTheme,
  Stack,
  IconButton,
  Button,
} from '@mui/material';
import { motion } from 'framer-motion';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, EffectFade, Autoplay } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/effect-fade';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';


const getIconForTechnology = (tech) => {
  switch (tech) {
    case 'React':
      return 'https://upload.wikimedia.org/wikipedia/commons/thumb/a/a7/React-icon.svg/1200px-React-icon.svg.png';
    case 'tailwindcss':
      return 'https://upload.wikimedia.org/wikipedia/commons/thumb/d/d5/Tailwind_CSS_Logo.svg/2048px-Tailwind_CSS_Logo.svg.png';
    case 'Figma':
      return 'https://upload.wikimedia.org/wikipedia/commons/thumb/3/33/Figma-logo.svg/1024px-Figma-logo.svg.png';
    case 'Illustrator':
      return 'https://upload.wikimedia.org/wikipedia/commons/thumb/f/fb/Adobe_Illustrator_CC_icon.svg/2101px-Adobe_Illustrator_CC_icon.svg.png';
    case 'Blender':
      return 'https://upload.wikimedia.org/wikipedia/commons/thumb/0/0c/Blender_logo.svg/1024px-Blender_logo.svg.png';
    case '3ds Max':
      return 'https://upload.wikimedia.org/wikipedia/commons/thumb/7/7a/3ds_Max_logo.svg/1024px-3ds_Max_logo.svg.png';
    case 'Krita':
      return 'https://upload.wikimedia.org/wikipedia/commons/thumb/0/0c/Krita_logo.svg/1200px-Krita_logo.svg.png';
    default:
      return '';
  }
};

const ProjectCard = ({ title, description, category, images, technologies, delay, index, link, buttonText }) => {
  const isEven = index % 2 === 0;
  
  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.8, delay }}
    >
      <Card
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', md: isEven ? 'row' : 'row-reverse' },
          borderRadius: '7px',
          overflow: 'hidden',
          boxShadow: '0 4px 6px -1px rgba(0, 0, 0, 0.1)',
          transition: 'transform 0.3s ease-in-out',
          '&:hover': {
            transform: 'translateY(-5px)',
          },
          mb: 4,
          height: { xs: 'auto', md: '400px' },
          position: 'relative',
        }}
      >
        <Box
          sx={{
            width: { xs: '100%', md: '50%' },
            height: { xs: '300px', md: '100%' },
            position: 'relative',
          }}
        >
          <Swiper
            modules={[Navigation, Pagination, EffectFade, Autoplay]}
            effect="fade"
            speed={1000}
            slidesPerView={1}
            loop={true}
            autoplay={{
              delay: 5000,
              disableOnInteraction: false,
            }}
            pagination={{
              clickable: true,
              renderBullet: (index, className) => {
                return `<span class="${className}" style="background: white;"></span>`;
              },
            }}
            navigation={{
              prevEl: `.prev-${index}`,
              nextEl: `.next-${index}`,
            }}
            style={{ height: '100%' }}
          >
            {images.map((image, i) => (
              <SwiperSlide key={i}>
                <Box
                  sx={{
                    height: '100%',
                    position: 'relative',
                  }}
                >
                  <Box
                    component="img"
                    src={image}
                    alt={`${title} - Image ${i + 1}`}
                    sx={{
                      width: '100%',
                      height: '100%',
                      objectFit: 'cover',
                    }}
                  />
                </Box>
              </SwiperSlide>
            ))}
          </Swiper>
          <IconButton
            className={`prev-${index}`}
            sx={{
              position: 'absolute',
              left: 10,
              top: '50%',
              transform: 'translateY(-50%)',
              zIndex: 2,
              color: 'white',
              '&.swiper-button-disabled': {
                opacity: 0.5,
                cursor: 'not-allowed',
              },
            }}
          >
            <ArrowBackIcon />
          </IconButton>
          <IconButton
            className={`next-${index}`}
            sx={{
              position: 'absolute',
              right: 10,
              top: '50%',
              transform: 'translateY(-50%)',
              zIndex: 2,
              color: 'white',
              '&.swiper-button-disabled': {
                opacity: 0.5,
                cursor: 'not-allowed',
              },
            }}
          >
            <ArrowForwardIcon />
          </IconButton>
        </Box>
        <CardContent
          sx={{
            width: { xs: '100%', md: '50%' },
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            p: '2%',
            background: 'white',
          }}
        >
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mb: 1 }}>
            <Typography
              variant="h3"
              component="h3"
              sx={{
                color: 'primary.main',
                fontWeight: 700,
                fontSize: '1.5rem',
                mb: 1,
                textAlign: 'center'
              }}
            >
              {category}
            </Typography>
            <Box sx={{ display: 'flex', flexWrap: 'wrap', padding: '8px', marginLeft: '16px', paddingLeft: '16px', width: '100%', justifyContent: 'center' }}>
              {technologies.map((tech, i) => (
                <Box key={tech} sx={{ display: 'flex', alignItems: 'center', marginRight: '8px', backgroundColor: 'rgba(124, 58, 237, 0.1)', borderRadius: '20px', padding: '4px', paddingLeft: '8px' }}>
                  <img src={getIconForTechnology(tech)} alt={tech} style={{ marginRight: '0px', width: '20px', height: '20px' }} />
                  <Typography key={tech} component="span" sx={{ display: 'inline-block', px: 1, py: 0.5, color: 'primary.main', fontSize: '0.9rem' }}>{tech}</Typography>
                </Box>
              ))}
            </Box>
          </Box>
          <Typography
            variant="h2"
            component="h2"
            sx={{ textAlign: { xs: 'center', md: 'left' } }}
          >
            {title}
          </Typography>
          <Typography
            variant="body1"
            color="text.secondary"
            sx={{
              fontSize: '1.1rem',
              lineHeight: 1.8,
              mb: 3,
              textAlign: { xs: 'center', md: 'left' }
            }}
          >
            {description}
          </Typography>
          {link && (
            <Box sx={{ display: 'flex', justifyContent: 'flex-start' }}>
              <Button variant="contained" color="primary" size="small" sx={{ margin: 1, padding: '16px 48px', fontWeight: 'bold', letterSpacing: '4px', textTransform: 'uppercase', borderRadius: '7px' }} onClick={() => window.open(link, '_blank')}>{buttonText}</Button>
            </Box>
          )}
        </CardContent>
      </Card>
    </motion.div>
  );
};

const Projects = () => {
  const theme = useTheme();

  const projects = [
    {
      title: 'KEREN-OR',
      description: 'Site vitrine pour une association de danses latines et de salons.',
      link: 'https://www.leadingdanse.fr',
      category: 'Web Design & Développement',
      technologies: ['React', 'tailwindcss'],
      images: [
        '/images/SiteKeren1.png',
        '/images/SiteKeren2.png',
        '/images/SiteKeren3.png',
       
      ],
      buttonText: 'Visiter',
    },
    {
      title: 'Destinée',
      description: 'Visual Novel Game. Prototype de l’application réalisée sur Figma.',
      category: 'UI/UX',
      technologies: ['Figma', 'Krita'],
      images: [
        '/images/Destinee2.PNG',
        '/images/Destinee1.PNG',
      ],
      link: 'https://embed.figma.com/proto/fyQjbZknvi6856Rosm0QFl/D%C3%A9stin%C3%A9e?node-id=14-3&p=f&scaling=scale-down&content-scaling=fixed&page-id=14%3A2&starting-point-node-id=14%3A3&embed-host=share',
      buttonText: 'Jouer',
    },
    {
      title: 'Classic Sport Driving',
      description: 'Refonte des menus du jeu vidéo.',
      category: 'UI/UX & Jeu Video',
      technologies: ['Figma', 'Illustrator'],
      images: [
        '/images/MenuDriving1.png',
        '/images/MenuDriving2.png',
      
      ],
    },
    {
      title: 'Réalisation 3D',
      description: 'Projets de modélisation et d’animation 3D.',
      category: '3D Design',
      technologies: ['Blender'], 
      images: [
        '/images/LPcamp.png'
      ],
      link: '',
    },
  ];

  return (
    <Box
      sx={{
        backgroundColor: theme.palette.background.default,
        color: theme.palette.text.primary,
        minHeight: '100vh',
      }}
    >
      <Container maxWidth="lg">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
        >
          <Typography
            variant="h1"
            component="h1"
            align="center"
            sx={{
              mb: 2,
              fontWeight: 700,
              color: theme.palette.text.primary,
              marginTop: '80px', // Ajout d'un espacement au-dessus du h1
            }}
          >
            Mes Réalisations
          </Typography>
        </motion.div>

        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8, delay: 0.2 }}
        >
          <Typography
            variant="h5"
            align="center"
            color="text.secondary"
            sx={{ mb: 8, maxWidth: '800px', mx: 'auto' }}
          >
            Découvrez mes derniers projets en design et développement
          </Typography>
        </motion.div>

        <Stack spacing={6}>
          {projects.map((project, index) => (
            <ProjectCard
              key={project.title}
              {...project}
              delay={0.4 + index * 0.2}
              index={index}
            />
          ))}
        </Stack>
      </Container>
    </Box>
  );
};

export default Projects;
