import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#7C3AED', // Couleur violette du logo et des boutons
      light: '#8B5CF6',
      dark: '#6D28D9',
    },
    secondary: {
      main: '#EC4899', // Couleur rose du dégradé
      light: '#F472B6',
      dark: '#DB2777',
    },
    background: {
      default: '#F5F3FF', // Couleur de fond légèrement violette
      paper: '#FFFFFF',
    },
    text: {
      primary: '#1F2937', // Texte foncé pour le contraste
      secondary: '#6B7280',
    },
    mode: 'light', // Ajout du mode clair
  },
  // Ajout de la configuration pour le thème sombre
  darkPalette: {
    mode: 'dark', // Mode sombre
    primary: {
      main: '#39FF14', // Vert néon
    },
    secondary: {
      main: '#FF007F', // Rose néon
    },
    background: {
      default: '#121212', // Fond sombre classique
      paper: '#424242', // Fond gris pour les cartes en mode sombre
    },
    text: {
      primary: '#FFFFFF', // Texte clair
      secondary: '#B0BEC5',
    },
  },
  typography: {
    fontFamily: '"Inter", "Helvetica", "Arial", sans-serif',
    h1: {
      fontSize: '3.5rem',
      fontWeight: 700,
      lineHeight: 1.2,
    },
    h2: {
      fontSize: '2.25rem',
      fontWeight: 700,
      lineHeight: 1.3,
    },
    h3: {
      fontSize: '1.875rem',
      fontWeight: 600,
      lineHeight: 1.4,
    },
    body1: {
      fontSize: '1rem',
      lineHeight: 1.5,
    },
    button: {
      textTransform: 'none',
      fontWeight: 600,
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: '8px',
          padding: '10px 20px',
        },
        contained: {
          background: 'linear-gradient(to right, #7C3AED, #EC4899)',
          '&:hover': {
            background: 'linear-gradient(to right, #6D28D9, #DB2777)',
          },
        },
        darkContained: {
          backgroundColor: '#39FF14', // Couleur de fond néon pour les boutons
          color: '#FFFFFF', // Couleur du texte
          '&:hover': {
            backgroundColor: '#FF4F81', // Couleur de fond au survol
          },
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: '16px',
          boxShadow: '0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)',
        },
      },
    },
  },
  darkTypography: {
    fontFamily: '"Inter", "Helvetica", "Arial", sans-serif',
    h1: {
      fontSize: '3.5rem',
      fontWeight: 700,
      lineHeight: 1.2,
      color: '#FFFFFF',
    },
    h2: {
      fontSize: '2.25rem',
      fontWeight: 700,
      lineHeight: 1.3,
      color: '#FFFFFF',
    },
    h3: {
      fontSize: '1.875rem',
      fontWeight: 600,
      lineHeight: 1.4,
      color: '#FFFFFF',
    },
    body1: {
      fontSize: '1rem',
      lineHeight: 1.5,
      color: '#FFFFFF',
    },
    button: {
      textTransform: 'none',
      fontWeight: 600,
      color: '#FFFFFF',
    },
  },
  darkComponents: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: '8px',
          padding: '10px 20px',
          backgroundColor: '#39FF14', // Couleur de fond néon pour les boutons
          color: '#FFFFFF', // Couleur du texte
          '&:hover': {
            backgroundColor: '#FF4F81', // Couleur de fond au survol
          },
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: '16px',
          boxShadow: '0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)',
          backgroundColor: '#424242', // Fond gris pour les cartes en mode sombre
        },
      },
    },
  },
});

export default theme;
