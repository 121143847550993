import React, { useState } from 'react';
import {
  Box,
  Container,
  Typography,
  TextField,
  Button,
  Grid,
  Paper,
  useTheme,
  useMediaQuery,
} from '@mui/material';
import { motion } from 'framer-motion';
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import emailjs from 'emailjs-com'; // Import emailjs
import Footer from '../Footer';


const ContactInfo = ({ icon, title, content }) => (
  <Box
    sx={{
      display: 'flex',
      alignItems: 'center',
      gap: 2,
      mb: { xs: 2, md: 3 },
    }}
  >
    <Box
      sx={{
        width: 48,
        height: 48,
        borderRadius: '12px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        background: 'linear-gradient(135deg, #7C3AED 0%, #EC4899 100%)',
        flexShrink: 0,
      }}
    >
      {React.cloneElement(icon, { sx: { color: 'white' } })}
    </Box>
    <Box>
      <Typography
        variant="subtitle1"
        sx={{
          fontWeight: 600,
          color: 'text.primary',
          mb: 0.5,
        }}
      >
        {title}
      </Typography>
      <Typography
        variant="body2"
        sx={{
          color: 'text.secondary',
        }}
      >
        {content}
      </Typography>
    </Box>
  </Box>
);

const Contact = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  // État pour le message de confirmation ou d'erreur
  const [message, setMessage] = useState('');
  const [isError, setIsError] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData(e.target);

    // Validation des champs
    if (!formData.get('name') || !formData.get('email') || !formData.get('message')) {
      alert('Tous les champs doivent être remplis.');
      return;
    }

    // Validation de l'email
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailPattern.test(formData.get('email'))) {
      alert('Veuillez entrer une adresse e-mail valide.');
      return;
    }

    try {
      const response = await emailjs.send('service_y35w7uh', 'template_w6fay1o', {
        name: formData.get('name'),
        email: formData.get('email'),
        subject: formData.get('subject'),
        message: formData.get('message'),
      }, 'EYtbkXU_q3wE4sstC');
      console.log('Message envoyé avec succès:', response);
      setMessage('Votre message a été envoyé avec succès !');
      setIsError(false);
    } catch (error) {
      console.error('Erreur lors de l’envoi du message:', error);
      setMessage('Une erreur est survenue lors de l’envoi de votre message. Veuillez réessayer plus tard.');
      setIsError(true);
    }
  };

  return (
    <Box
      sx={{
        backgroundColor: theme.palette.background.default,
        color: theme.palette.text.primary,
        minHeight: '100vh',
        py: { xs: 6, md: 12 },
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <Container maxWidth="lg">
        <Typography
          variant="h2"
          align="center"
          sx={{ mb: 4, fontWeight: 700 }}
        >
          Contact
        </Typography>

        {/* Affichage du message de confirmation ou d'erreur */}
        {message && (
          <Paper sx={{ padding: 2, marginBottom: 4, background: 'linear-gradient(135deg, #7C3AED 0%, #EC4899 100%)', color: 'white', textAlign: 'center', margin: 'auto' }}>
            <Typography>{message}</Typography>
          </Paper>
        )}

        <Grid container spacing={4} direction="row" justifyContent="center">
          <Grid item xs={12} md={9}>
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.8, delay: 0.2 }}
            >
              <Paper
                elevation={0}
                sx={{
                  p: { xs: 3, sm: 4 },
                  borderRadius: '20px',
                  background: 'white',
                  boxShadow: '0 4px 6px -1px rgba(0, 0, 0, 0.1)',
                  mb: 4,
                }}
              >
                <Typography
                  variant="h5"
                  sx={{ mb: 2, fontWeight: 600 }}
                >
                  Formulaire de Contact
                </Typography>
                <form onSubmit={handleSubmit}>
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        fullWidth
                        label="Nom"
                        name="name"
                        variant="outlined"
                        required
                        sx={{
                          '& .MuiOutlinedInput-root': {
                            borderRadius: '12px',
                          },
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        fullWidth
                        label="Email"
                        type="email"
                        name="email"
                        variant="outlined"
                        required
                        sx={{
                          '& .MuiOutlinedInput-root': {
                            borderRadius: '12px',
                          },
                        }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        label="Sujet"
                        name="subject"
                        variant="outlined"
                        required
                        sx={{
                          '& .MuiOutlinedInput-root': {
                            borderRadius: '12px',
                          },
                        }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        label="Message"
                        multiline
                        rows={4}
                        name="message"
                        variant="outlined"
                        required
                        sx={{
                          '& .MuiOutlinedInput-root': {
                            borderRadius: '12px',
                          },
                        }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Button
                        type="submit"
                        variant="contained"
                        fullWidth
                        size="large"
                        sx={{
                          py: 1.5,
                          borderRadius: '12px',
                          fontSize: '1.1rem',
                          textTransform: 'none',
                          background: 'linear-gradient(135deg, #7C3AED 0%, #EC4899 100%)',
                          '&:hover': {
                            background: 'linear-gradient(135deg, #6D28D9 0%, #DB2777 100%)',
                          },
                        }}
                      >
                        Envoyer le message
                      </Button>
                    </Grid>
                  </Grid>
                </form>
              </Paper>
            </motion.div>
          </Grid>
          <Grid item xs={12} md={3}>
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.8 }}
            >
              <Paper
                elevation={0}
                sx={{
                  p: { xs: 3, sm: 4 },
                  borderRadius: '20px',
                  background: 'white',
                  boxShadow: '0 4px 6px -1px rgba(0, 0, 0, 0.1)',
                  mb: 4,
                }}
              >
                <Typography
                  variant="h5"
                  sx={{ mb: 2, fontWeight: 600 }}
                >
                  Coordonnées
                </Typography>
                <ContactInfo icon={<LocationOnIcon />} title="Adresse" content="Astra Lux - 1 rue madame de Sévigné, 59540 Caudry" />
                <ContactInfo icon={<EmailIcon />} title="Email" content="zoe.dero@gmail.com" />
                <ContactInfo icon={<PhoneIcon />} title="Téléphone" content={<a href="tel:0787260964">0787260964</a>} />
              </Paper>
            </motion.div>
          </Grid>
        </Grid>
      </Container>
      <Footer />
    </Box>
  );
};

export default Contact;
