import React from 'react';
import { Box, Typography, Link } from '@mui/material';

const Footer = () => {
    return (
        <Box sx={{ p: 3, backgroundColor: '#f5f5f5', textAlign: 'center' }}>
            <Typography variant="body2" color="text.secondary">
                Astra Lux - 1 rue madame de Sévigné, 59540 Caudry
            </Typography>
            <Typography variant="body2" color="text.secondary">
                &copy; {new Date().getFullYear()} Astra Lux. Tous droits réservés.
            </Typography>
            <Typography variant="body2" color="text.secondary">
                <Link href="#" color="inherit">Politique de confidentialité</Link> | 
                <Link href="#" color="inherit">Conditions d'utilisation</Link>
            </Typography>
            <Typography variant="body2" color="text.secondary">
                Suivez-nous sur : 
                <Link href="#" color="inherit">Facebook</Link>, 
                <Link href="#" color="inherit">Twitter</Link>, 
                <Link href="#" color="inherit">Instagram</Link>
            </Typography>
        </Box>
    );
};

export default Footer;
