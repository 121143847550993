import React from "react";
import {
  Box,
  Container,
  Typography,
  Button,
  Grid,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import { motion } from "framer-motion";
import { Link as RouterLink } from "react-router-dom";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

const Home = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const isTablet = useMediaQuery(theme.breakpoints.down("lg"));
  const astraluxLogo = "/images/astraluxLogo.png";

  return (
    <Box
      sx={{
        backgroundColor: theme.palette.background.default,
        color: theme.palette.text.primary,
        minHeight: "100vh",
        display: "flex",
        alignItems: "center",
        py: { xs: 8, md: 0 },
      }}
    >
      <Container maxWidth="lg">
        <Grid container spacing={4} alignItems="center">
          <Grid item xs={12} md={6} order={{ xs: 2, md: 1 }}>
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.8 }}
            >
              <Typography
                variant="h1"
                sx={{
                  marginTop: "80px",
                  fontWeight: 800,
                  fontSize: { xs: "2.5rem", sm: "3rem", md: "4rem" },
                  lineHeight: { xs: 1.2, md: 1.1 },
                  mb: { xs: 2, md: 3 },
                  textAlign: { xs: "center", md: "left" },
                  background:
                    "linear-gradient(135deg, #7C3AED 0%, #EC4899 100%)",
                  WebkitBackgroundClip: "text",
                  WebkitTextFillColor: "transparent",
                }}
              >
                Créations Digitales & Design Innovant
              </Typography>
              <Typography
                variant="h2"
                sx={{
                  fontSize: { xs: "1.25rem", sm: "1.5rem", md: "1.75rem" },
                  color: "text.secondary",
                  mb: { xs: 4, md: 6 },
                  textAlign: { xs: "center", md: "left" },
                  maxWidth: "600px",
                  mx: { xs: "auto", md: 0 },
                }}
              >
                Développement web, design UI/UX, ainsi que photographie et
                infographie, pour donner vie à vos projets numériques avec des
                solutions créatives.
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  gap: 2,
                  flexDirection: { xs: "column", sm: "row" },
                  justifyContent: { xs: "center", md: "flex-start" },
                }}
              >
                <Button
                  component={RouterLink}
                  to="/projects"
                  variant="contained"
                  size="large"
                  endIcon={<ArrowForwardIcon />}
                  sx={{
                    py: 1.5,
                    px: 4,
                    fontSize: "1.1rem",
                    textTransform: "none",
                    borderRadius: "12px",
                    background:
                      "linear-gradient(135deg, #7C3AED 0%, #EC4899 100%)",
                    "&:hover": {
                      background:
                        "linear-gradient(135deg, #6D28D9 0%, #DB2777 100%)",
                    },
                  }}
                >
                  Voir mes projets
                </Button>
                <Button
                  component={RouterLink}
                  to="/contact"
                  variant="outlined"
                  size="large"
                  sx={{
                    py: 1.5,
                    px: 4,
                    fontSize: "1.1rem",
                    textTransform: "none",
                    borderRadius: "12px",
                    borderWidth: "2px",
                    borderColor: "#7C3AED",
                    color: "#7C3AED",
                    "&:hover": {
                      borderWidth: "2px",
                      borderColor: "#6D28D9",
                      background: "rgba(124, 58, 237, 0.04)",
                    },
                  }}
                >
                  Me contacter
                </Button>
              </Box>
            </motion.div>
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            order={{ xs: 1, md: 2 }}
            sx={{
              display: "flex",
              justifyContent: "center",
              mb: { xs: 4, md: 0 },
            }}
          >
            <motion.div
              initial={{ opacity: 0, scale: 0.8 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{ duration: 0.8, delay: 0.2 }}
              style={{
                maxWidth: isTablet ? "400px" : "500px",
                aspectRatio: "1",
                borderRadius: "50%",
                background:
                  "linear-gradient(135deg, rgba(124, 58, 237, 0.1) 50%, rgba(236, 72, 153, 0.1) 70%)",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                padding: "20px",
              }}
              whileHover={{
                scale: 1.05,
                boxShadow:
                  "0 8px 12px rgba(124, 58, 237, 0.5), 0 4px 8px rgba(236, 72, 153, 0.5)",
              }}
           
            >
              <img
                src={astraluxLogo}
                alt="Astralux Logo"
                style={{ width: "100%" }}
              />
            </motion.div>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default Home;
