import React, { useRef } from 'react';
import {
  Container,
  Typography,
  Box,
  useTheme,
} from '@mui/material';
import { motion } from 'framer-motion';
import { Swiper, SwiperSlide } from 'swiper/react';
import { EffectCards } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/effect-cards';
import CodeIcon from '@mui/icons-material/Code';
import BrushIcon from '@mui/icons-material/Brush';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import WebIcon from '@mui/icons-material/Web';


const CornerLogo = ({ icon, position }) => {
  const positionStyles = {
    'top-left': { top: 20, left: 20 },
    'top-right': { top: 20, right: 20 },
    'bottom-left': { bottom: 20, left: 20 },
    'bottom-right': { bottom: 20, right: 20 },
  };

  return (
    <Box
      sx={{
        position: 'absolute',
        ...positionStyles[position],
        width: 40,
        height: 40,
        borderRadius: '50%',
        backgroundColor: 'rgba(124, 58, 237, 0.8)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        zIndex: 2,
        '.swiper-slide-active &': {
          display: 'none',
        },
      }}
    >
      {icon}
    </Box>
  );
};

const ServiceCard = ({ icon, title, description, image }) => {
  return (
    <Box
      sx={{
        height: '100%',
        width: '100%',
        borderRadius: '20px',
        overflow: 'hidden',
        position: 'relative',
        '&::before': {
          content: '""',
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          background: 'linear-gradient(to bottom, rgba(0,0,0,0.3) 0%, rgba(0,0,0,0.8) 100%)',
          zIndex: 1,
        },
      }}
    >
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundImage: `url("${image}")`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          filter: 'brightness(0.7)',
        }}
      />
      <CornerLogo icon={icon} position="top-left" />
      <CornerLogo icon={icon} position="top-right" />
      <CornerLogo icon={icon} position="bottom-left" />
      <CornerLogo icon={icon} position="bottom-right" />
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          textAlign: 'center',
          width: '80%',
          zIndex: 2,
          color: 'white',
        }}
      >
        <Box
          sx={{
            backgroundColor: 'rgba(124, 58, 237, 0.8)',
            borderRadius: '50%',
            width: '80px',
            height: '80px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            margin: '0 auto 20px',
            '.swiper-slide:not(.swiper-slide-active) &': {
              display: 'none',
            },
          }}
        >
          {icon}
        </Box>
        <Typography
          variant="h3"
          sx={{
            fontWeight: 700,
            mb: 2,
            textShadow: '2px 2px 4px rgba(0,0,0,0.3)',
            fontSize: { xs: '1.75rem', sm: '2rem' },
            '.swiper-slide:not(.swiper-slide-active) &': {
              display: 'none',
            },
          }}
        >
          {title}
        </Typography>
        <Typography
          variant="body1"
          sx={{
            fontSize: { xs: '1rem', sm: '1.1rem' },
            lineHeight: 1.6,
            textShadow: '1px 1px 2px rgba(0,0,0,0.3)',
            px: { xs: 2, sm: 0 },
            '.swiper-slide:not(.swiper-slide-active) &': {
              display: 'none',
            },
          }}
        >
          {description}
        </Typography>
      </Box>
    </Box>
  );
};

const Services = () => {
  const theme = useTheme();
  const swiperRef = useRef(null);

  const handleCardClick = (index) => {
    if (swiperRef.current && swiperRef.current.swiper) {
      const swiper = swiperRef.current.swiper;
      const currentIndex = swiper.realIndex;
      const diff = index - currentIndex;
      
      // Calculer le chemin le plus court
      const totalSlides = swiper.slides.length;
      if (Math.abs(diff) > totalSlides / 2) {
        if (diff > 0) {
          swiper.slideTo(currentIndex - (totalSlides - diff));
        } else {
          swiper.slideTo(currentIndex + (totalSlides + diff));
        }
      } else {
        swiper.slideTo(index);
      }
    }
  };

  const services = [
    {
      icon: <WebIcon sx={{ fontSize: 24, color: 'white' }} />,
      title: 'UI/UX Design',
      description: 'Création d\'interfaces utilisateur intuitives et d\'expériences utilisateur optimisées',
      image: '/images/uiux-bg.jpg',
    },
    {
      icon: <BrushIcon sx={{ fontSize: 24, color: 'white' }} />,
      title: 'Design Graphique',
      description: 'Conception d\'identités visuelles uniques et de supports de communication impactants',
      image: '/images/design-bg.jpg',
    },
    {
      icon: <CodeIcon sx={{ fontSize: 24, color: 'white' }} />,
      title: 'Développement Web',
      description: 'Développement de sites web et d\'applications sur mesure avec les dernières technologies',
      image: '/images/dev-bg.jpg',
    },
    {
      icon: <CameraAltIcon sx={{ fontSize: 24, color: 'white' }} />,
      title: 'Photographie',
      description: 'Captation d\'images professionnelles pour mettre en valeur vos produits et votre marque',
      image: '/images/photo-bg.jpg',
    },
  ];

  return (
    <Box
      sx={{
        backgroundColor: theme.palette.background.default,
        color: theme.palette.text.primary,
        minHeight: '100vh',
      }}
    >
      <Container sx={{ width: '100%', maxWidth: '100%' }}>
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
        >
          <Typography
            variant="h2"
            component="h1"
            align="center"
            sx={{
              mb: 2,
              fontWeight: 700,
              fontSize: { xs: '2rem', sm: '2.5rem', md: '3rem' },
              px: { xs: 2, sm: 0 },
              marginTop: '80px', // Ajout d'un espacement au-dessus du h1
            }}
          >
            Mes Services
          </Typography>
        </motion.div>

        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8, delay: 0.2 }}
        >
          <Typography
            variant="h5"
            align="center"
            color="text.secondary"
            sx={{ 
              mb: { xs: 6, sm: 8 }, 
              maxWidth: '800px', 
              mx: 'auto',
              px: { xs: 2, sm: 0 },
              fontSize: { xs: '1.1rem', sm: '1.25rem' },
            }}
          >
            Des solutions créatives et professionnelles pour vos projets
          </Typography>
        </motion.div>

        <Box
          sx={{
            maxWidth: { xs: '100%', sm: '500px' },
            mx: 'auto',
            px: { xs: 2, sm: 0 },
            '.swiper': {
              width: '100%',
              padding: { xs: '40px', sm: '80px' },
            },
            '.swiper-slide': {
              borderRadius: '20px',
              backgroundColor: 'white',
              transition: 'all 0.3s ease',
              transform: 'scale(0.9)',
              cursor: 'pointer',
              height: { xs: '350px', sm: '450px' },
              '&:hover': {
                filter: 'brightness(1.1)',
              },
            },
            '.swiper-slide-active': {
              transform: { xs: 'scale(1.05)', sm: 'scale(1.1)' },
              cursor: 'default',
              '&:hover': {
                filter: 'none',
              },
            },
            '.swiper-slide-shadow-left, .swiper-slide-shadow-right': {
              borderRadius: '20px',
            },
            '.swiper-slide:not(.swiper-slide-active)': {
              opacity: 0.8,
            },
            '.swiper-wrapper': {
              perspective: '1500px',
            },
          }}
        >
          <Swiper
            ref={swiperRef}
            effect="cards"
            grabCursor={false}
            modules={[EffectCards]}
            className="mySwiper"
            cardsEffect={{
              perSlideOffset: 12,
              perSlideRotate: 5,
              slideShadows: true,
            }}
          >
            {services.map((service, index) => (
              <SwiperSlide 
                key={service.title}
                onClick={() => handleCardClick(index)}
              >
                {service.title === 'UI/UX Design' && (
                  <Box className="uiux-background">
                    <div className="card-overlay"></div>
                    <ServiceCard {...service} style={{ width: '350px', height: '450px' }} />
                  </Box>
                )}
                {service.title === 'Design Graphique' && (
                  <Box className="graphic-design-background">
                    <div className="card-overlay"></div>
                    <ServiceCard {...service} style={{ width: '350px', height: '450px' }} />
                  </Box>
                )}
                {service.title === 'Développement Web' && (
                  <Box className="development-background">
                    <div className="card-overlay"></div>
                    <ServiceCard {...service} style={{ width: '350px', height: '450px' }} />
                  </Box>
                )}
                {service.title === 'Photographie' && (
                  <Box className="photography-background">
                    <div className="card-overlay"></div>
                    <ServiceCard {...service} style={{ width: '350px', height: '450px' }} />
                  </Box>
                )}
              </SwiperSlide>
            ))}
          </Swiper>
        </Box>
      </Container>
    </Box>
  );
};

export default Services;
